<template>
    <modal
        v-if="model"
        v-model="model"
        :header="isEditing ? $t('lists.editButton.shippingZone') : $t('lists.addButton.shippingZone')"
    >
        <form class="modal__content form">
            <progress-circular class="modal__spinner spinner" v-if="isLoading"></progress-circular>
            <template v-else>
                <form-item v-bind="currentState.fields.name.props" class="form__input">
                    <input-text
                        @update-field="onUpdateFieldValue"
                        v-model="currentState.name"
                        class="form__input"
                        name="name"
                    ></input-text>
                </form-item>
                <span class="divider modal__divider" />
                <form-item v-bind="currentState.fields.zones.props" class="form__input">
                    <search-form
                        class="form__input modal__search"
                        only-model
                        v-model="search"
                        @clear-search="onClearSearch"
                    ></search-form>
                    <ZonesTree
                        v-model="selection"
                        @input="validateCountries"
                        :search="search"
                        :items="countriesList"
                    ></ZonesTree>
                </form-item>
            </template>
        </form>
        <Button class="modal__save-btn" :disabled="!isSaveAllowed" icon="check" @click="save">{{
            $t('entities.save')
        }}</Button>
    </modal>
</template>

<script>
import { cloneDeep, debounce, isEqual } from 'lodash';
import { proxyModelMixin } from '@/mixins/proxyModelMixin';
import Modal from '@/components/common/Modal';
import SearchForm from '@/components/search/SearchForm';
import ShippingZone from '@/entities/delivery/ShippingZone';
import FormItem from '@/components/form/item';
import EventEmitter from '@/helpers/eventEmitter.ts';
import Button from '@/components/common/Button';
import InputText from '@/components/form/controls/InputText';
import ProgressCircular from '@/components/common/ProgressCircular';
import ZonesTree from '@/components/delivery/ZonesTree';
export default {
    name: 'shippingZoneModal',
    components: { ZonesTree, InputText, ProgressCircular, FormItem, SearchForm, Modal, Button },
    mixins: [proxyModelMixin],
    props: {
        value: {
            type: Boolean,
        },
        zone: {
            type: Object,
            default: () => new ShippingZone(),
        },
        isEditing: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            countriesList: [],
            selection: null,
            search: '',
            isLoading: false,
            currentState: {},
            initialState: null,
            saved: false,
        };
    },
    methods: {
        getFlag(code) {
            return require(`@/assets/images/flags/${code.toLowerCase()}.svg`);
        },
        save() {
            this.currentState.zones = this.selection;
            const isValid = this.validateForm();
            if (!isValid) return;
            if (this.isEditing) this.$emit('update-zone', this.currentState);
            else this.$emit('create-zone', this.currentState);
            this.saved = true;
        },
        onUpdateFieldValue: debounce(function(payload) {
            const { name, value } = payload;
            this.currentState.fields[name].props.errors = this.currentState.validateField(
                this.currentState.fields[name],
                value
            );
        }, 1000),
        onClearSearch() {
            this.search = '';
        },
        validateCountries(val) {
            this.currentState.fields.zones.props.errors = this.currentState.validateField(
                this.currentState.fields.zones,
                val
            );
        },
        validateForm() {
            let result = true;
            const fieldKeys = Object.keys(this.currentState.fields);

            fieldKeys.forEach((key) => {
                const errors = this.currentState.validateField(this.currentState.fields[key], this.currentState[key]);
                if (errors.length !== 0) result = false;
                this.$set(this.currentState.fields[key].props, 'errors', errors);
            });
            if (result === false)
                EventEmitter.trigger('show-noty', {
                    type: 'error',
                    text: this.$t('notifications.validation.error'),
                });
            return result;
        },
    },
    computed: {
        isSaveAllowed() {
            if (this.initialState) {
                return !isEqual(this.initialState.data, this.currentState.data);
            }
            return false;
        },
    },
    watch: {
        zone: {
            handler(val) {
                const zone = new ShippingZone(val);
                this.isLoading = true;
                this.currentState = cloneDeep(zone);
                this.initialState = cloneDeep(zone);
                this.selection = [...this.currentState.zones];
                setTimeout(() => (this.isLoading = false), 300);
            },
            immediate: true,
        },
        value(val) {
            if (val) {
                // modal open
                this.countriesList = ShippingZone.formatCountriesForTree();
            } else {
                // modal close
                if (!this.saved) {
                    // reset changes in modal on close without save
                    this.currentState = cloneDeep(this.initialState);
                    this.selection = [...this.currentState.zones];
                }
                this.search = '';
                this.saved = false;
            }
        },
        selection(val) {
            this.currentState.zones = val;
        },
    },
};
</script>

<style lang="scss">
@import '@/scss/variables.scss';
.tree {
    width: 100%;
    height: 300px;
    overflow-y: scroll;
    overflow-x: hidden;
    &__item {
        display: flex;
        gap: 4px;
    }

    .material-icons-outlined::after {
        display: none !important;
    }
    &__img {
        margin-top: -4px;
        width: 23px;
    }
}
</style>
